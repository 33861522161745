<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Name" horizontal>
              <b-input
                v-model="form.name"
                required
              />
            </b-field>
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Profil Semester"
          icon="account"
          class="tile is-child"
        >
          <!-- <user-avatar
            :avatar="form.avatar"
            class="image has-max-width is-aligned-center"
          /> -->
          <!-- <hr /> -->
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
    // vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false,
      kbk: [],
      jenjang: [],
      kelas: []
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = 'Edit semester'
      } else {
        lastCrumb = 'Tambah Semester'
      }

      return ['Pengaturan', 'Semester Akademik', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return 'Edit Semester'
      } else {
        return 'Tambah Semester'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'semester.create' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Tambah Semester'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Semester'
      } else {
        return 'Tambah Semester'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
  },
  created () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        noid: null,
        name: null,
        email: null,
        phone: null
      }
    },
    getData () {
      if (this.$route.params.id) {
        const id = parseInt(this.$route.params.id)
        axios
          .get(`/semester/${id}`)
          .then((r) => {
            console.log(r.data)
            if (r.data.status === 'ok') {
              this.form = r.data.data
              this.isProfileExists = true
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.$router.push({ name: 'semester' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form
      data.password = data.noid

      this.isLoading = true

      if (this.$route.params.id) {
        axios
          .put(`/semester/${this.$route.params.id}`, data)
          .then(this.handleResponse)
          .catch(this.handleError)
      } else {
        axios
          .post('/semester', data)
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    }
  }
}
</script>
